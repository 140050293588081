import React from 'react';
import './external-page-modal.scss';

import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import { TranslatableText } from './language';

export default ({ isOpen, toggle, openLink, isRoche }) => (
  <Modal isOpen={isOpen} toggle={toggle} className={'modal-lg'} id="external-page-modal">
    <ModalHeader toggle={toggle}>
      <TranslatableText
        dictionary={{ english: 'Thank you for visiting our site.' }}
      />
    </ModalHeader>
    <ModalBody>
      <div className="external-container">
        {isRoche !== "true" &&
          <p>
            You are about to leave the site to one that is not controlled or developed by Roche Products (New Zealand) Ltd.
             Roche does not endorse or control the content of any linked websites and if you click OK, you agree that Roche has
             no responsibility or duty to you for anything that happens to you as a result. If the linked site is outside
             New Zealand's jurisdiction, the information may not be consistent with New Zealand's legislation, advertising codes
             or the relevant New Zealand registered Data Sheet. Please refer to the Data Sheet or Consumer Medicine Information
             on the Medsafe website for product information applicable to New Zealand.
          </p>
        }
        {isRoche === "true" &&
          <p>
            You are about to leave the site to one that is affiliated to Roche Products (New Zealand) Ltd. Roche Products (New Zealand) does not endorse or control,
             the content of any linked websites and if you click OK, you agree that Roche has no responsibility or duty to you for anything that happens to you as a result.
             If the linked site is outside New Zealand’s jurisdiction, the information may not be consistent with New Zealand’s legislation, advertising codes or the
             relevant New Zealand registered Data Sheet. Please refer to the Data Sheet or Consumer Medicine Information on the Medsafe website for product information
             applicable to New Zealand.

          </p>
        }
        <p>Click OK to continue.</p>
        <div className="btns-container">
          <button onClick={openLink} className="btn-open">
            <TranslatableText dictionary={{ english: 'OK', french: 'Je comprends' }} />
          </button>
          <button onClick={toggle} className="btn-back">
            <TranslatableText dictionary={{ english: 'Take me back', french: 'Retourner sur le site' }} />
          </button>
        </div>
      </div>
    </ModalBody>
  </Modal>
);
